<template>
  <div id="test_Index5">
    <div class="row">
      <div class="column nav-area">
        <nav>
          <h1>Penelope's Floral Design</h1>
          <a href="#">Fresh Floral</a>
          <a href="#">Dried & Silk</a>
          <a href="#">Succulent</a>
          <a href="#">Special Events</a>
        </nav>
      </div>
      <section class="column">
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/kerry-hu-Kb0NBElwDww-unsplash.jpg">
          <div class="overlay">
            <h2>Paradise in Pink</h2>
            <div class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus luctus luctus aliquet. Morbi ac blandit massa. Etiam a est sit amet libero porttitor fringilla et ut arcu. Quisque auctor sem vel dui consequat, sit amet lacinia nisl pellentesque. Curabitur auctor convallis lacus, eget semper ligula auctor in. Vestibulum in risus augue. Aenean tempor tincidunt vehicula</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/johnny-fu-QT25msM_v-g-unsplash.jpg">
          <div class="overlay">
            <h2>The Masterpiece</h2>
            <div class="text">Fusce malesuada mi lorem, sit amet consequat arcu porta a. Aenean elementum mi turpis. Integer tincidunt venenatis gravida. Proin eget pellentesque enim, eget egestas sapien.</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/anna-cicognani-RRl4UPoC-8E-unsplash.jpg">
          <div class="overlay">
            <h2>Minimalist Elegance</h2>
            <div class="text">Quisque vitae rhoncus neque. Nunc cursus auctor ornare. Pellentesque et congue metus, in sagittis sapien. Proin tincidunt ligula a sem ultrices, eu aliquam augue sodales</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/hailey-wagner-4icV47LjYc4-unsplash.jpg">
          <div class="overlay">
            <h2>Sunset Succulents</h2>
            <div class="text">Duis turpis risus, ornare vel consectetur et, convallis non risus. Etiam et nisi eget risus tincidunt lobortis cursus ac sem. Pellentesque leo tellus, fermentum eu consectetur ac, posuere sit amet neque.</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/luisa-brimble-Ikcn0alusdg-unsplash.jpg">
          <div class="overlay">
            <h2>The Winter Garden</h2>
            <div class="text">Pellentesque commodo, odio id auctor commodo, diam dui vehicula tortor, ac posuere eros ipsum at quam. Duis elementum, lectus sit amet condimentum congue, quam neque pulvinar neque, quis suscipit libero dui vel massa. Proin egestas, ante eu dignissim aliquam, diam lorem cursus felis, vel varius urna mi non lorem. Nam mattis arcu vel mi pellentesque cursus.</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/marcella-marcella-hn6CC9aosEk-unsplash.jpg">
          <div class="overlay">
            <h2>Spring Radiance</h2>
            <div class="text">Morbi dictum tincidunt ante id condimentum. Curabitur ligula libero, sodales id semper non, finibus a ligula. Aenean arcu nisl, vehicula nec fermentum a, auctor a ligula. Praesent in vulputate ligula.</div>
          </div>
        </div>
      </section>

      <section class="column second">
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/hailey-wagner-4icV47LjYc4-unsplash.jpg">
          <div class="overlay">
            <h2>Sunset Succulents</h2>
            <div class="text">Duis turpis risus, ornare vel consectetur et, convallis non risus. Etiam et nisi eget risus tincidunt lobortis cursus ac sem. Pellentesque leo tellus, fermentum eu consectetur ac, posuere sit amet neque.</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/luisa-brimble-Ikcn0alusdg-unsplash.jpg">
          <div class="overlay">
            <h2>The Winter Garden</h2>
            <div class="text">Pellentesque commodo, odio id auctor commodo, diam dui vehicula tortor, ac posuere eros ipsum at quam. Duis elementum, lectus sit amet condimentum congue, quam neque pulvinar neque, quis suscipit libero dui vel massa. Proin egestas, ante eu dignissim aliquam, diam lorem cursus felis, vel varius urna mi non lorem. Nam mattis arcu vel mi pellentesque cursus.</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/marcella-marcella-hn6CC9aosEk-unsplash.jpg">
          <div class="overlay">
            <h2>Spring Radiance</h2>
            <div class="text">Morbi dictum tincidunt ante id condimentum. Curabitur ligula libero, sodales id semper non, finibus a ligula. Aenean arcu nisl, vehicula nec fermentum a, auctor a ligula. Praesent in vulputate ligula.
            </div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/kerry-hu-Kb0NBElwDww-unsplash.jpg">
          <div class="overlay">
            <h2>Paradise in Pink</h2>
            <div class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus luctus luctus aliquet. Morbi ac blandit massa. Etiam a est sit amet libero porttitor fringilla et ut arcu. Quisque auctor sem vel dui consequat, sit amet lacinia nisl pellentesque. Curabitur auctor convallis lacus, eget semper ligula auctor in. Vestibulum in risus augue. Aenean tempor tincidunt vehicula</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/anna-cicognani-RRl4UPoC-8E-unsplash.jpg">
          <div class="overlay">
            <h2>Minimalist Elegance</h2>
            <div class="text">Quisque vitae rhoncus neque. Nunc cursus auctor ornare. Pellentesque et congue metus, in sagittis sapien. Proin tincidunt ligula a sem ultrices, eu aliquam augue sodales</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/johnny-fu-QT25msM_v-g-unsplash.jpg">
          <div class="overlay">
            <h2>The Masterpiece</h2>
            <div class="text">Fusce malesuada mi lorem, sit amet consequat arcu porta a. Aenean elementum mi turpis. Integer tincidunt venenatis gravida. Proin eget pellentesque enim, eget egestas sapien.</div>
          </div>
        </div>
      </section>
      <section class="column third">
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/marcella-marcella-hn6CC9aosEk-unsplash.jpg">
          <div class="overlay">
            <h2>Spring Radiance</h2>
            <div class="text">Morbi dictum tincidunt ante id condimentum. Curabitur ligula libero, sodales id semper non, finibus a ligula. Aenean arcu nisl, vehicula nec fermentum a, auctor a ligula. Praesent in vulputate ligula.</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/anna-cicognani-RRl4UPoC-8E-unsplash.jpg">
          <div class="overlay">
            <h2>Minimalist Elegance</h2>
            <div class="text">Quisque vitae rhoncus neque. Nunc cursus auctor ornare. Pellentesque et congue metus, in sagittis sapien. Proin tincidunt ligula a sem ultrices, eu aliquam augue sodales
            </div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/kerry-hu-Kb0NBElwDww-unsplash.jpg">
          <div class="overlay">
            <h2>Paradise in Pink</h2>
            <div class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus luctus luctus aliquet. Morbi ac blandit massa. Etiam a est sit amet libero porttitor fringilla et ut arcu. Quisque auctor sem vel dui consequat, sit amet lacinia nisl pellentesque. Curabitur auctor convallis lacus, eget semper ligula auctor in. Vestibulum in risus augue. Aenean tempor tincidunt vehicula</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/hailey-wagner-4icV47LjYc4-unsplash.jpg">
          <div class="overlay">
            <h2>Sunset Succulents</h2>
            <div class="text">Duis turpis risus, ornare vel consectetur et, convallis non risus. Etiam et nisi eget risus tincidunt lobortis cursus ac sem. Pellentesque leo tellus, fermentum eu consectetur ac, posuere sit amet neque.</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/johnny-fu-QT25msM_v-g-unsplash.jpg">
          <div class="overlay">
            <h2>The Masterpiece</h2>
            <div class="text">Fusce malesuada mi lorem, sit amet consequat arcu porta a. Aenean elementum mi turpis. Integer tincidunt venenatis gravida. Proin eget pellentesque enim, eget egestas sapien.</div>
          </div>
        </div>
        <div class="box-flex">
          <img src="https://assets.codepen.io/652/luisa-brimble-Ikcn0alusdg-unsplash.jpg">
          <div class="overlay">
            <h2>The Winter Garden</h2>
            <div class="text">Pellentesque commodo, odio id auctor commodo, diam dui vehicula tortor, ac posuere eros ipsum at quam. Duis elementum, lectus sit amet condimentum congue, quam neque pulvinar neque, quis suscipit libero dui vel massa. Proin egestas, ante eu dignissim aliquam, diam lorem cursus felis, vel varius urna mi non lorem. Nam mattis arcu vel mi pellentesque cursus.</div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {parseTime} from '@/utils';
export default {
  name: "test_Index5",
  data() {
    return {
      list: [
        {
          title: '1',
          date: new Date()
        }
      ]
    }
  },
  methods: {
    parseToTime(time){
      return parseTime(time)
    }
  },
  created() {

  }
}
</script>
<style src="@/assets/css/css2.css"  scoped></style>
<style scoped lang="less">
/* References: https://www.w3schools.com/howto/howto_css_image_grid_responsive.asp https://www.imarketinx.de/artikel/text-centered-on-image.html
https://www.w3schools.com/howto/howto_css_image_overlay.asp */

body {
  margin: 0;
  padding: 0;
  font-family: "Aref Ruqaa Ink", serif;
}

h1 {
  margin-top: 150px;
  text-align: center;
  max-width: 100px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.column {
  flex: 23%;
  max-width: 23%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: MediumVioletRed;
}

nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

nav a {
  border-top: thin solid black;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
}

.box-flex {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  font-size: 0.7rem;
  word-break: break-word;
  color: #d9d9d9;
  opacity: 1;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  word-break: break-word;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: scroll;
  width: 100%;
  height: 98%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s ease;
  transition: 0.7s ease;
}

.box-flex:hover .overlay {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.text {
  padding: 15px;
}

@media screen and (max-width: 800px) {
  .column {
    flex: 47%;
    max-width: 47%;
  }
  .box-flex {
    font-size: 0.9rem;
  }
  .second,
  .third {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
  .box-flex {
    font-size: 0.9rem;
  }
  .second,
  .third {
    display: none;
  }
  h1 {
    max-width: 300px;
    margin-top: 50px;
  }
  nav a {
    border-top: none;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 5px;
  }
  nav a:last-child {
    margin-bottom: 30px;
  }
}

</style>
